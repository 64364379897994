import {PricingSliderEnum} from '@/enums';
import Toggle from '@/components/shared/Plans/Toggle/Toggle';
import {useTranslations} from '@/hooks/useTranslations';

export default function MonthYearSlider({
  sliderValue,
  handleToggle,
  toggleId,
  gradientClassName,
  annualText,
}: {
  sliderValue: PricingSliderEnum;
  handleToggle: (newValue: PricingSliderEnum) => void;
  toggleId?: string;
  gradientClassName?: string;
  annualText?: string;
  planNamesToShow: string[];
}) {
  const {t} = useTranslations();

  const sliderAnnualText = annualText
    ? annualText
    : t('pricing:sliderAnnualText');
  const sliderMonthText = t('pricing:sliderMonthText');
  return (
    <>
      <h2 id="subscription-cadence" className="sr-only">
        {t('pricing:sliderContextHeader', {
          sliderText:
            sliderValue === PricingSliderEnum.Yearly
              ? sliderAnnualText
              : sliderMonthText,
        })}
      </h2>
      <Toggle
        toggleId={toggleId || 'pricingMonthYearToggle'}
        labelOne={sliderMonthText}
        labelTwo={sliderAnnualText}
        sliderValue={sliderValue}
        toggle={handleToggle}
        gradientClassName={gradientClassName}
      />
    </>
  );
}
