import cn from 'classnames';
import {useCallback} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import {PricingSliderEnum} from '@/enums';
import {twMerge} from '@/stylesheets/twMerge';
import {useFormatCurrency} from '@/utils/string/formatCurrency';
import {PromotionType} from '@/components/shared/Plans/types';

interface PriceProps {
  showPromoBanner: boolean;
  sliderValue: PricingSliderEnum;
  currencyCode: string;
  price: number;
  showMonth?: boolean;
  subClassName?: string;
  planName: string;
  sliderToggleId?: string;
  discountAmount?: number;
  disclaimer?: string;
  isPlusPlan?: boolean;
  promotion?: PromotionType;
}

export default function PriceDisplay({
  showPromoBanner,
  sliderValue,
  currencyCode,
  price,
  showMonth,
  subClassName,
  planName,
  sliderToggleId,
  discountAmount,
  disclaimer,
  isPlusPlan,
  promotion,
}: PriceProps) {
  const {t} = useTranslations();
  const formatCurrency = useFormatCurrency();
  const formattedPrice = formatCurrency(price, {
    maximumFractionDigits: 0,
    currency: currencyCode,
  });
  const discountedFormattedPrice = formatCurrency(discountAmount, {
    maximumFractionDigits: 0,
    currency: currencyCode,
  });

  const isYearly = sliderValue === PricingSliderEnum.Yearly;
  const isSpainPricingPromotion =
    promotion === PromotionType.SpainPricingPromotion;
  const discountMonthly =
    !isYearly &&
    discountAmount &&
    discountedFormattedPrice &&
    !isSpainPricingPromotion;
  const spaceHolder = <span>&nbsp;</span>;

  const getPrefixText = () => {
    if (isSpainPricingPromotion || isPlusPlan || discountMonthly) {
      return (
        <>
          {t('pricing:startingAt')}
          {(isSpainPricingPromotion || discountMonthly) && (
            <>
              {' '}
              <s>
                {isSpainPricingPromotion
                  ? discountedFormattedPrice
                  : formattedPrice}
              </s>
            </>
          )}
        </>
      );
    }

    return spaceHolder;
  };
  const pricingTermText = isPlusPlan
    ? t('pricing:plusPricingTerm')
    : isYearly
      ? t('pricing:yearlyPricingText')
      : spaceHolder;

  const getDisplayPrice = useCallback(() => {
    if (discountMonthly) {
      return discountedFormattedPrice;
    }

    return formattedPrice;
  }, [discountMonthly, discountedFormattedPrice, formattedPrice]);

  const getScreenReaderText = () => {
    let subscriptionPriceText;
    if (isPlusPlan) {
      subscriptionPriceText = t('pricing.screenReaderTriennialSubPriceText');
    } else {
      const subscriptionType = isYearly ? 'Annual' : 'Monthly';
      subscriptionPriceText = t(
        `pricing:screenReader${subscriptionType}SubPriceText`,
      );
    }

    const billingPeriodText = isYearly
      ? t('pricing:billingPeriodScreenReaderText')
      : '';
    const promoPeriodText = showPromoBanner
      ? t('pricing:promoPeriodScreenReaderText')
      : '';

    return [
      subscriptionPriceText,
      formattedPrice,
      currencyCode,
      billingPeriodText,
      promoPeriodText,
    ]
      .filter(Boolean)
      .join(' ');
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-wrap" aria-hidden="true">
        <span className="text-sm block my-1 w-full text-shade-50">
          {getPrefixText()}
        </span>
        <span className="flex flex-nowrap">
          <span
            className="text-5xl lg:text-[56px] tracking-tight font-bold pr-1"
            data-price
          >
            {getDisplayPrice()}
          </span>
          <sub
            className={twMerge(
              cn(
                'mt-1 lg:mt-2 text-base leading-4 text-shade-80 font-bold flex flex-col',
                subClassName,
              ),
            )}
          >
            <span>{currencyCode}</span>
            <span>
              {showMonth ? `${t('pricing:month')}` : `${t('pricing:cadence')}`}
              {disclaimer && '*'}
            </span>
          </sub>
        </span>
        <span className="text-sm block mt-1 w-full text-shade-50">
          {pricingTermText}
        </span>
      </div>
      <div
        className="sr-only"
        role="tabpanel"
        id={`price-display-for-${planName}`}
        aria-labelledby={
          sliderToggleId
            ? `${sliderToggleId}-plan-toggle`
            : `${sliderValue}-plan-toggle`
        }
      >
        {getScreenReaderText()}
      </div>
    </div>
  );
}
