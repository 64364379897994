import {useRef} from 'react';
import cn from 'classnames';

import GradientBorderCard from '@/components/brochureV2/primitive/GradientBorderCard/GradientBorderCard';
import {PricingSliderEnum} from '@/enums';

import {getTabIndex} from '../utilities/tabIndex';

interface ToggleProps {
  gradientClassName?: string;
  toggleId: string;
  labelOne: string;
  labelTwo: string;
  toggle: (value: PricingSliderEnum) => void;
  disabled?: boolean;
  sliderValue: PricingSliderEnum;
}

export default function Toggle({
  gradientClassName,
  toggleId = 'toggle',
  labelOne,
  labelTwo,
  toggle,
  disabled = false,
  sliderValue,
}: ToggleProps) {
  const toggleItems = [
    {
      toggleValue: PricingSliderEnum.Monthly,
      label: labelOne,
      ref: useRef<HTMLButtonElement>(null),
    },
    {
      toggleValue: PricingSliderEnum.Yearly,
      label: labelTwo,
      ref: useRef<HTMLButtonElement>(null),
    },
  ];

  function handleKeyDown(
    index: number,
    event: React.KeyboardEvent<HTMLButtonElement>,
  ) {
    const tabCount = toggleItems.length;
    const newTabIndex = getTabIndex({index, event, tabCount});

    if (newTabIndex !== undefined) {
      const newTab = toggleItems[newTabIndex];
      toggle(newTab.toggleValue);
      newTab.ref.current?.focus();
    }
  }

  return (
    <GradientBorderCard
      gradient={
        gradientClassName
          ? gradientClassName
          : 'linear-gradient(114.33deg, #00FF75 18.38%, #5FD1BE 55.15%, #15bcdf 88.27%)'
      }
      borderWidth={2}
      borderRadius="58px"
      className="my-6 inline-block text-center h-auto"
      cardColorClassName="bg-white"
    >
      <div
        role="tablist"
        aria-labelledby="subscription-cadence"
        style={{
          // @ts-expect-error - css var
          '--count': toggleItems.length,
          '--active': toggleItems.findIndex(
            (item) => item.toggleValue === sliderValue,
          ),
        }}
        className={cn(
          'grid grid-flow-col bg-white rounded-full auto-cols-[1fr]',
          'relative border-4 border-solid border-white',
        )}
      >
        <div
          style={{
            translate: 'calc(var(--active, 0) * 100%) 0',
          }}
          className={cn(
            'pointer-events-none content-[""] w-[calc(100%/var(--count))]',
            'h-full bg-white absolute',
            'rounded-full mix-blend-difference',
            'transition-[translate,_outline-color] duration-200',
            'ease-in-out outline outline-2 outline-transparent',
            'motion-reduce:transition-none',
          )}
        ></div>
        {toggleItems.map(({label, toggleValue, ref}, index) => {
          const selected = toggleValue === sliderValue;
          const tabIndex = selected ? undefined : -1;

          return (
            <button
              ref={ref}
              key={label}
              aria-controls={`tab-panel-for-subscription-cadence-${toggleValue}`}
              id={`${toggleId}-${toggleValue}-plan-toggle`}
              aria-selected={selected}
              role="tab"
              tabIndex={tabIndex}
              disabled={disabled}
              onClick={() => {
                toggle(toggleValue);
              }}
              onKeyDown={(event) => handleKeyDown(index, event)}
              className={cn(
                'px-4 py-2 cursor-pointer w-full h-full grid rounded-full',
                'place-items-center whitespace-nowrap',
                'text-center xs:text-xs xs:leading-5 sm:text-sm sm:-leading-5',
                'hover:bg-black/10 focus:bg-black/10',
                'transition-[background,_color] duration-200 ease-in-out',
                'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-state-focus',
              )}
            >
              {label}
            </button>
          );
        })}
      </div>
    </GradientBorderCard>
  );
}
