import {PlanName, PricingSliderEnum} from '@/enums';
import {useDiscountedPriceVariantSpain} from '@/hooks/useDiscountedPriceVariantSpain';

import type {Plan} from '../FeaturesTable/utils/normalizePlanTypes';

const getIncentive = ({
  active,
  billingCycle,
  plan,
}: {
  active: boolean;
  billingCycle: PricingSliderEnum;
  plan: Partial<Plan>;
}) => {
  if (
    !active ||
    (plan.internal_name !== PlanName.Basic &&
      plan.internal_name !== PlanName.Shopify)
  )
    return undefined;

  switch (billingCycle) {
    case PricingSliderEnum.Yearly:
      return plan.incentives?.spain_pricing_promotion_annual;
    case PricingSliderEnum.Monthly:
      return plan.incentives?.spain_pricing_promotion_monthly;
    default:
      return undefined;
  }
};

const getStrikethroughPrice = ({
  currentIncentive,
  billingCycle,
  plan,
}: {
  currentIncentive?: ReturnType<typeof getIncentive>;
  billingCycle: PricingSliderEnum;
  plan: Partial<Plan>;
}):
  | {
      amount: number;
      currencyCode: string;
    }
  | undefined => {
  if (!currentIncentive) return undefined;

  const isYearly = billingCycle === PricingSliderEnum.Yearly;
  const {amount, currency} = currentIncentive.discount;
  const fullPrice = isYearly
    ? plan.annual_price!.amount
    : plan.monthly_price!.amount;
  const discountedAmount = fullPrice + parseFloat(amount);

  return {
    amount: isYearly ? Math.round(discountedAmount / 12) : discountedAmount,
    currencyCode: currency,
  };
};

/**
 * @param {plan: Plan, billingCycle: PricingSliderEnum}
 * @returns Whether Spain pricing promotion is active and the strikethrough price
 */
export const useSpainPricingPromotion = ({
  plan,
  billingCycle,
}: {
  plan: Partial<Plan>;
  billingCycle: PricingSliderEnum;
}) => {
  const active = useDiscountedPriceVariantSpain();

  const currentIncentive = getIncentive({active, billingCycle, plan});
  const strikethroughPrice = getStrikethroughPrice({
    currentIncentive,
    billingCycle,
    plan,
  });

  return {
    active,
    strikethroughPrice: strikethroughPrice,
  };
};
