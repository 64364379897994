import cn from 'classnames';
import type {PropsWithChildren} from 'react';
import {useState} from 'react';

import Link from '@/components/base/elements/Link/Link';
import Section from '@/components/base/layouts/Section/Section';
import {PricingSliderEnum} from '@/enums';
import {usePricingData} from '@/hooks/usePricingData';
import {useTranslations} from '@/hooks/useTranslations';
import type {LinkComponent} from '@/types';
import {twMerge} from '@/stylesheets/twMerge';
import MobilePricingPlansTabs from '@/components/shared/Plans/MobilePricingPlansTabs';
import MonthYearSlider from '@/components/shared/Plans/MonthYearSlider';
import PlanFeaturesTableMobile, {
  type PlanFeaturesTableMobileProps,
} from '@/components/shared/Plans/FeaturesTable/PlanFeaturesTableMobile';
import {type normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import {ResponsivePlanCardsRedesign} from '@/components/shared/Plans/ResponsivePlanCardsRedesign';
import type {ComponentKey} from '@/components/shared/Plans/types';
import {GmvRewardsIncentiveDisclaimer} from '@/components/shared/GmvRewardsIncentive/GmvRewardsIncentiveComponents';

import type {PaidTrialType} from './FeaturesTable/utils/normalizePlanTypes';

export interface PlansProps
  extends Partial<Pick<PlanFeaturesTableMobileProps, 'serverTableData'>> {
  /** @todo make this optional after it has been propagated to all instances */
  serverPlansData?: ReturnType<typeof normalizeServerPlanSummaryData>;
  planNamesToShow?: string[];
  link?: LinkComponent;
  sectionName: string;
  className?: string;
  pricingPlansWrapperClasses?: string;
  hasSlider?: boolean;
  rowsToDisplay?: ComponentKey[];
  paidTrial: PaidTrialType;
}

interface PricingPlansProps extends PropsWithChildren {}
interface PricingPlansProps
  extends Pick<PlansProps, 'pricingPlansWrapperClasses'> {
  plansCount: number;
  plansIncludeRecommended: boolean;
}

export const PricingPlans = ({
  children,
  pricingPlansWrapperClasses,
  plansCount,
  plansIncludeRecommended,
}: PricingPlansProps) => {
  const classNames =
    pricingPlansWrapperClasses ||
    cn(
      'flex flex-col md:gap-6 md:flex-row md:m-auto md:pl-0 md:pr-0 justify-center md:px-[2%] xl:px-0 transition-all',
      {
        'md:max-w-5xl': plansCount === 2,
        'md:mb-10': plansIncludeRecommended,
      },
    );

  return <div className={classNames}>{children}</div>;
};

export default function Plans({
  serverPlansData,
  planNamesToShow,
  serverTableData,
  link,
  className,
  pricingPlansWrapperClasses,
  sectionName,
  hasSlider = false,
  rowsToDisplay,
  paidTrial,
  ...props
}: PlansProps) {
  const {t} = useTranslations();
  const {geoPricingIsEnabled} = usePricingData();
  const [sliderValue, setSliderValue] = useState(PricingSliderEnum.Yearly);
  const [mobileTabActivePlan, setMobileTabActivePlan] =
    useState<string>('basic');

  const showYearDiscountDisclaimer = serverPlansData?.mainPlans.some(
    (plan: any) =>
      plan.annual_monthly_price.amount === plan.monthly_price.amount,
  );

  function getBasicPlanDisplayPrice() {
    const plan = serverPlansData?.mainPlans?.[0];
    return {
      amount:
        plan?.[
          sliderValue === PricingSliderEnum.Yearly
            ? 'annual_monthly_price'
            : 'monthly_price'
        ].amount || 0,
      currencyCode: plan?.annual_monthly_price?.currency_code,
      annualMonthlyRate: plan?.annual_monthly_price?.amount || 0,
    };
  }

  const basicPlan = getBasicPlanDisplayPrice();

  function onMobilePlanChange(newPlan: string) {
    setMobileTabActivePlan(newPlan);
  }

  const toggleId = 'pricingMonthYearToggle';

  return (
    <Section
      {...props}
      sectionName={`${sectionName}-${sliderValue}`}
      className={twMerge('py-0', className)}
      data-viewable-component
      data-component-extra-annual-basic-monthly-amount={
        basicPlan.annualMonthlyRate
      }
    >
      <div className="md:container relative">
        {hasSlider && (
          <div className="h-24 md:h-12 md:mb-12 md:mt-12 md:m-auto md:max-w-[90%] flex justify-center">
            <div
              className="h-12 mb-1"
              data-component-extra-basic-plan-amount={basicPlan.amount}
              data-component-extra-basic-plan-currency={basicPlan.currencyCode}
            >
              <MonthYearSlider
                annualText={t('pricing:sliderAnnualTextWithAsterix')}
                planNamesToShow={
                  planNamesToShow ??
                  serverPlansData?.mainPlans.map(
                    (item) => item.internal_name,
                  ) ??
                  []
                }
                toggleId={toggleId}
                sliderValue={sliderValue}
                handleToggle={(newValue) => setSliderValue(newValue)}
                gradientClassName="linear-gradient(#45F298, #45F298)"
              />
            </div>
          </div>
        )}

        <MobilePricingPlansTabs
          onMobileTabActivePlanChange={onMobilePlanChange}
          mobileTabActivePlan={mobileTabActivePlan}
          plans={
            serverPlansData &&
            serverPlansData?.mainPlans?.map((plan) => {
              return {
                name: plan?.internal_name,
                displayName: plan?.display_name,
                currencyCode: plan?.monthly_price.currency_code,
                price: {
                  annualPrice: plan?.annual_monthly_price.amount,
                  monthlyPrice: plan?.monthly_price.amount,
                },
              };
            })
          }
          sliderValue={sliderValue}
        />

        {serverPlansData && (
          <PricingPlans
            plansCount={serverPlansData?.mainPlans?.length || 0}
            plansIncludeRecommended
          >
            <ResponsivePlanCardsRedesign
              mobileTabActivePlan={mobileTabActivePlan}
              sliderValue={sliderValue}
              serverPlansData={serverPlansData}
              rowsToDisplay={rowsToDisplay}
              toggleId={toggleId}
              sliderToggleId={`${toggleId}-${sliderValue}`}
              paidTrial={paidTrial}
            />
          </PricingPlans>
        )}

        {serverPlansData && showYearDiscountDisclaimer ? (
          <p className="text-[#6D7175] text-center max-md:mt-10 text-sm hidden md:block">
            {t('pricing:discountPricesDisclaimer')}
          </p>
        ) : null}
        <GmvRewardsIncentiveDisclaimer
          supNumber={1}
          containerClasses="hidden md:block"
        />
        {link?.url && (
          <div
            className={cn(
              'text-center',
              serverPlansData ? 'mt-8' : 'mt-6 md:mt-16',
            )}
          >
            <Link
              href={link.url}
              componentName={link.name || ''}
              className="ml-7"
            >
              {link.text}
            </Link>
          </div>
        )}

        <PlanFeaturesTableMobile
          serverTableData={serverTableData}
          mobileTabActivePlan={mobileTabActivePlan}
        />

        {serverPlansData && showYearDiscountDisclaimer ? (
          <p className="text-[#6D7175] text-center max-md:mt-10 text-sm block md:hidden">
            {t('pricing:discountPricesDisclaimer')}
          </p>
        ) : null}
        <GmvRewardsIncentiveDisclaimer
          supNumber={1}
          containerClasses="block md:hidden"
        />
        {geoPricingIsEnabled && (
          <div className="text-center text-sm text-shade-50">
            {t('pricing:geoPricesDisclaimer')}
          </div>
        )}
      </div>
    </Section>
  );
}
