import Typography from '@/components/base/elements/Typography/Typography';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';
import {useTranslations} from '@/hooks/useTranslations';
import {formatCurrency} from '@/utils/string/formatCurrency';
import useIntlLocale from '@/hooks/useIntlLocale';
import {isPlusPlan} from '@/components/shared/Plans/utils';

const GMV_PLAN_PAYOUT_CAP: {[key: string]: number} = {
  basic: 5_000,
  professional: 7_500,
  retail: 7_500,
  unlimited: 10_000,
};

interface GmvRewardsIncentivePlanBadgeProps {
  planName: string | undefined;
  containerClasses?: string;
  supNumber: number;
}

export const GmvRewardsIncentivePlanBadge = ({
  planName,
  containerClasses,
  supNumber,
}: GmvRewardsIncentivePlanBadgeProps) => {
  const {isTreatment: isGmvRewardsIncentiveEnabled} =
    useGmvRewardsAcquisitionIncentive();
  const {t} = useTranslations();
  const locale = useIntlLocale();
  if (!planName || !supNumber || !isGmvRewardsIncentiveEnabled) return null;
  // We need to make sure all pricing cards are aligned when we show the badge on some of them
  const isPlus = isPlusPlan(planName);
  const planPayoutCap = GMV_PLAN_PAYOUT_CAP[planName];
  if (!planPayoutCap && !isPlus) return null;

  return (
    <div className={`flex ${containerClasses} ${isPlus && 'invisible'}`}>
      <div className="bg-[#DBF5E9] rounded-lg px-2">
        <Typography
          as="span"
          className="text-[#303030] text-[13px] font-inter-regular leading-6"
        >
          {t('incentives.gmvRewards.badgeText', {
            supNumber: supNumber,
            planPayoutCap: formatCurrency(locale, 'USD', planPayoutCap, {
              maximumFractionDigits: 0,
            }),
          })}
        </Typography>
      </div>
    </div>
  );
};

interface GmvRewardsIncentiveDisclaimerProps {
  containerClasses?: string;
  supNumber: number;
}

export const GmvRewardsIncentiveDisclaimer = ({
  supNumber,
  containerClasses,
}: GmvRewardsIncentiveDisclaimerProps) => {
  const {isTreatment: isGmvRewardsIncentiveEnabled} =
    useGmvRewardsAcquisitionIncentive();
  const {t} = useTranslations();
  if (!supNumber) return null;

  return isGmvRewardsIncentiveEnabled ? (
    <Typography
      className={`text-[#6D7175] text-center max-md:mt-1 text-sm ${containerClasses}`}
    >
      {t('pricing:gmvRewardsIncentiveDisclaimer', {supNumber: supNumber})}
    </Typography>
  ) : null;
};
