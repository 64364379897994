import cn from 'classnames';

import {useMediaQuery} from '@/hooks/useMediaQuery';
import {twMerge} from '@/stylesheets/twMerge';

export default function GradientBorderCard({
  gradient,
  className,
  cardColorClassName = 'bg-black',
  borderWidth = 1,
  borderRadius = '8px',
  hideOnMobile = false,
  children,
}: {
  gradient?: string;
  className?: string;
  cardColorClassName?: string;
  borderWidth?: number;
  borderRadius?: string;
  hideOnMobile?: boolean;
  children: React.ReactNode;
}) {
  const isMobileView = useMediaQuery('(max-width: 639px)');
  const hideBorder = hideOnMobile && isMobileView;

  return (
    <div
      className={twMerge(cn('h-full', className))}
      style={{
        padding: `${borderWidth}px`,
        borderRadius: hideBorder ? 0 : borderRadius,
        backgroundImage: hideBorder ? 'none' : gradient || undefined,
      }}
    >
      <div
        className={cn(cardColorClassName, 'overflow-hidden h-full')}
        style={{
          backgroundClip: 'content-box',
          borderRadius: hideBorder ? 0 : borderRadius,
          backgroundColor: hideBorder ? 'transparent' : undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
}
