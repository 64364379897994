import {PricingSliderEnum} from '@/enums';
import useSignup from '@/hooks/useSignup';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import {formatCurrency} from '@/utils/string/formatCurrency';
import {FLAG_RSI_RETAIL_PLAN_INCENTIVE} from '@/flags';
import {useFlag} from '@/hooks/useFlag';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import {isPlusPlan} from '@/components/shared/Plans/utils';
import type {
  ComponentKey,
  ResponsivePlanCardsRedesignProps,
  PlanProps,
  PlanCardsProps,
} from '@/components/shared/Plans/types';
import {
  Banner,
  PlanDetails,
  CardRates,
  StandoutFeatures,
  PlanCardCTA,
  PlanCardCTAWithSecondaryLink,
  CardAddon,
  type BannerProps,
  type PlanDetailsProps,
  type CardAddonProps,
  type CardRatesProps,
  type StandoutFeaturesProps,
  type PlanCardCTAProps,
} from '@/components/shared/Plans/ResponsivePlanCardContent';

type ComponentPropsMapping = {
  Banner: BannerProps;
  PlanDetails: PlanDetailsProps;
  CardAddon: CardAddonProps;
  CardRates: CardRatesProps;
  StandoutFeatures: StandoutFeaturesProps;
  PlanCardCTA: PlanCardCTAProps;
  PlanCardCTAWithSecondaryLink: PlanCardCTAProps;
};
const defaultRowsToDisplay: ComponentKey[] = [
  'Banner',
  'PlanDetails',
  'CardRates',
  'StandoutFeatures',
  'PlanCardCTA',
];

const componentMapping = {
  Banner,
  PlanDetails,
  CardRates,
  StandoutFeatures,
  PlanCardCTA,
  PlanCardCTAWithSecondaryLink,
  CardAddon,
};

const PlanCards = ({
  mainPlans,
  sliderValue,
  colorTheme,
  displayAlternatePlans,
  alternatePlansTranslations: otherPlansTranslation,
  rowsToDisplay = defaultRowsToDisplay,
  addCardContentDivider = true,
  retailPosPrice,
  sliderToggleId,
  showSpRateIncentive,
  paidTrial,
}: PlanCardsProps) => {
  const {t: tPlans} = useSharedTranslations('plans');
  const {t: tPricing} = useTranslations();
  const {site} = useSiteData();
  const enableRetailIncentive = useFlag(FLAG_RSI_RETAIL_PLAN_INCENTIVE);
  const {isSPRateIncentive, termsLink} = useRetailIncentive();
  const {signupUrl} = useSignup();

  const plans = mainPlans || [];

  const translations = tPlans('plans') || {};
  const alternatePlansTranslations = otherPlansTranslation || [];
  const getCardAddon = (plan: PlanProps) => {
    const isStarterCard = plan.internal_name === 'starter_2022';
    const isRetailCard = plan.internal_name === 'retail';

    const alternatePlansTranslation = alternatePlansTranslations.find(
      (translation) => translation.name === plan.internal_name,
    );

    const showAddonMonthlyIncentive =
      Boolean(alternatePlansTranslation?.addOnMonthlyIncentive) &&
      sliderValue === PricingSliderEnum.Monthly;

    let cardAddon = showAddonMonthlyIncentive
      ? alternatePlansTranslation?.addOnMonthlyIncentive
      : alternatePlansTranslation?.addOn;

    const formatPosProPrice = (amount: any) => {
      return `<span class="font-bold">+ ${formatCurrency(
        site.locale,
        retailPosPrice?.currencyCode,
        amount,
        {
          maximumFractionDigits: 0,
        },
      )}</span>&nbsp; ${alternatePlansTranslation?.addOn}`;
    };

    cardAddon =
      isStarterCard || isRetailCard
        ? alternatePlansTranslation?.addOn
        : sliderValue === PricingSliderEnum.Yearly
          ? formatPosProPrice(retailPosPrice?.yearly)
          : showAddonMonthlyIncentive
            ? alternatePlansTranslation?.addOnMonthlyIncentive
            : formatPosProPrice(retailPosPrice?.monthly);

    return cardAddon;
  };

  return (
    <div
      className="grid min-[1120px]:gap-x-3 drop-shadow-xl"
      style={{
        gridTemplateRows: `repeat(${rowsToDisplay.length}, auto)`,
        gridTemplateColumns: `repeat(${plans.length}, 1fr)`,
        gridAutoFlow: 'column dense',
      }}
    >
      {plans.map((item, idx) => {
        const isFirst = idx === 0;
        const isLast = idx === plans.length - 1;
        const isPlus = isPlusPlan(item.internal_name);
        const planColorTheme = isPlus ? 'blue' : colorTheme;
        let price =
          sliderValue === PricingSliderEnum.Yearly
            ? item.annual_monthly_price
            : item.monthly_price;
        if (isPlus) {
          price = item.triennial_monthly_price;
        }

        const {callout, disclaimer, discount} =
          (enableRetailIncentive && item?.incentives?.retail_plan_incentive) ||
          {};

        const spRateIncentive = item?.incentives?.retail_sp_rate_incentive;
        const enableSpRateIncentive =
          isSPRateIncentive && spRateIncentive && showSpRateIncentive;
        const spRateDisclaimerWithTerms =
          spRateIncentive?.disclaimer +
          tPricing('global:incentivesPromoBanner.retail.spRateTermsLink', {
            incentiveTerms: termsLink,
          });

        const monthlyAmount = Number(item.monthly_price.amount);
        const discountAmount =
          discount && monthlyAmount
            ? monthlyAmount - Number(discount.amount)
            : undefined;

        const ctaLinkText = displayAlternatePlans
          ? alternatePlansTranslations[idx]?.link?.text
          : translations[item.internal_name]?.link?.text;

        const ctaLinkUrl = isPlus
          ? translations[item.internal_name]?.link?.url
          : signupUrl;

        const addOn =
          colorTheme === 'lime' ? getCardAddon(item) || '' : undefined;
        const secondaryLink = () => {
          if (isPlus) {
            return translations[item.internal_name]?.secondaryLink;
          }
          return colorTheme === 'lime'
            ? alternatePlansTranslations[idx]?.secondaryLink
            : undefined;
        };

        const getRowToDisplayProps = (
          rowToDisplay: ComponentKey,
        ): ComponentPropsMapping[typeof rowToDisplay] => {
          switch (rowToDisplay) {
            case 'Banner': {
              const propsForComponent: BannerProps = {
                paidTrialAmount: paidTrial.price,
                paidTrialMonths: paidTrial.length,
                plansLength: plans.length,
                colorTheme: planColorTheme,
                isPlusPlan: isPlus,
                isFirst,
                isLast,
              };
              return propsForComponent;
            }
            case 'PlanDetails': {
              const propsForComponent: PlanDetailsProps = {
                idx,
                colorTheme: planColorTheme,
                sliderToggleId,
                planName: {
                  item,
                  badge: item.recommended
                    ? tPricing('pricing:recommendedText')
                    : callout,
                },
                planPricing: {
                  price,
                  sliderValue,
                  addCardContentDivider,
                  discountAmount,
                },
                disclaimer,
                isPlusPlan: isPlus,
              };
              return propsForComponent;
            }
            case 'CardAddon': {
              const propsForComponent: CardAddonProps = {
                addOn: addOn || '',
                isStarter: item.internal_name === 'starter_2022',
              };
              return propsForComponent;
            }
            case 'CardRates': {
              const propsForComponent: CardRatesProps = {
                plan: item,
                colorTheme: planColorTheme,
                isPlusPlan: isPlus,
                enableSpRateIncentive: enableSpRateIncentive,
              };
              return propsForComponent;
            }
            case 'StandoutFeatures': {
              const propsForComponent: StandoutFeaturesProps = {
                plan: item,
                colorTheme: planColorTheme,
              };
              return propsForComponent;
            }
            case 'PlanCardCTA': {
              const propsForComponent: PlanCardCTAProps = {
                plansLength: plans.length,
                item,
                price,
                ctaLinkUrl,
                ctaLinkText,
                colorTheme: planColorTheme,
                disclaimer: enableSpRateIncentive
                  ? spRateDisclaimerWithTerms
                  : disclaimer,
                isFirst,
                isLast,
              };
              return propsForComponent;
            }
            case 'PlanCardCTAWithSecondaryLink': {
              const propsForComponent: PlanCardCTAProps = {
                plansLength: plans.length,
                item,
                price,
                ctaLinkUrl,
                ctaLinkText,
                colorTheme: planColorTheme,
                secondaryLink: secondaryLink(),
                isPlusPlan: isPlus,
                isFirst,
                isLast,
              };
              return propsForComponent;
            }
          }
        };

        return (
          <div key={item.internal_name} className="contents">
            {rowsToDisplay?.map((_row: ComponentKey) => {
              const row = isPlus ? adjustForPlus(_row) : _row;
              const Component = componentMapping[row];

              const propsForComponent = getRowToDisplayProps(row);

              const ComponentToRender = Component as React.ComponentType<
                typeof propsForComponent
              >;

              return (
                <ComponentToRender
                  key={`${row}-${item.internal_name}`}
                  {...propsForComponent}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const ResponsivePlanCardsRedesign = ({
  serverPlansData,
  mobileTabActivePlan,
  sliderValue,
  displayAlternatePlans,
  rowsToDisplay,
  alternatePlansTranslations,
  addCardContentDivider,
  colorTheme = 'green',
  badge,
  sliderToggleId,
  showSpRateIncentive = false,
  toggleId,
  paidTrial,
}: ResponsivePlanCardsRedesignProps) => {
  const plans = (() => {
    if (displayAlternatePlans) {
      let filteredAlternatePlans = serverPlansData?.alternatePlans?.filter(
        (f) => !isPlusPlan(f.internal_name),
      );
      return filteredAlternatePlans || [];
    }
    return serverPlansData?.mainPlans || [];
  })();

  const retailPlanData = serverPlansData?.alternatePlans?.find(
    (r) => r.internal_name === 'retail',
  );

  const retailPosPrice = {
    monthly: retailPlanData?.monthly_price.amount,
    yearly: retailPlanData?.annual_monthly_price.amount,
    currencyCode: retailPlanData?.monthly_price.currency_code,
  };

  const getMobileActivePlan = (activePlan: string) => {
    if (activePlan === 'starter') {
      return 'starter_2022';
    }

    return activePlan;
  };

  const mobilePlans = plans.filter((item) => {
    return item.internal_name === getMobileActivePlan(mobileTabActivePlan);
  });

  return (
    <div
      id={`tab-panel-for-subscription-cadence-${sliderValue}`}
      role="tabpanel"
      aria-labelledby={`${toggleId}-${sliderValue}-plan-toggle`}
    >
      <div
        className="block max-md:hidden flex-grow"
        data-testid="desktop-plan-cards-pricing"
      >
        <PlanCards
          sliderToggleId={sliderToggleId}
          retailPosPrice={retailPosPrice}
          colorTheme={colorTheme}
          mainPlans={plans || []}
          sliderValue={sliderValue}
          rowsToDisplay={rowsToDisplay}
          displayAlternatePlans={displayAlternatePlans}
          badge={badge}
          alternatePlansTranslations={alternatePlansTranslations}
          addCardContentDivider={addCardContentDivider}
          showSpRateIncentive={showSpRateIncentive}
          paidTrial={paidTrial}
        />
      </div>
      <div
        className="max-md:block hidden flex-grow"
        data-testid="mobile-plan-cards-pricing"
        id={`tab-panel-for-${mobilePlans[0].internal_name}`}
        role="tabpanel"
        aria-labelledby={`tab-button-for-${mobilePlans[0].internal_name}`}
      >
        <PlanCards
          sliderToggleId={sliderToggleId}
          retailPosPrice={retailPosPrice}
          colorTheme={colorTheme}
          badge={badge}
          displayAlternatePlans={displayAlternatePlans}
          rowsToDisplay={rowsToDisplay}
          addCardContentDivider={addCardContentDivider}
          mainPlans={mobilePlans || []}
          sliderValue={sliderValue}
          alternatePlansTranslations={alternatePlansTranslations}
          showSpRateIncentive={showSpRateIncentive}
          paidTrial={paidTrial}
        />
      </div>
    </div>
  );
};

function adjustForPlus(row: ComponentKey) {
  return row === 'PlanCardCTA' ? 'PlanCardCTAWithSecondaryLink' : row;
}
