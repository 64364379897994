import cn from 'classnames';

import {type PricingSliderEnum} from '@/enums';
import {START_FREE_TRIAL_NAME, PLUS_GET_IN_TOUCH} from '@/constants';
import PriceDisplay from '@/pages/shopify.com/($locale)/pricing/components/PriceDisplay/PriceDisplay';
import {useTranslations} from '@/hooks/useTranslations';
import Button from '@/components/base/elements/Button/Button';
import {useSignupUrl} from '@/hooks/useSignupUrl';
import {
  type PlanProps,
  type PlanCardTheme,
  PromotionType,
} from '@/components/shared/Plans/types';
import {DivHtml} from '@/components/shared/HtmlElements';
import SvgCheck from '@/components/base/elements/Icon/icons/Check';
import Typography from '@/components/base/elements/Typography/Typography';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import {GmvRewardsIncentivePlanBadge} from '@/components/shared/GmvRewardsIncentive/GmvRewardsIncentiveComponents';

import ShopifyPlusIconLogo from './assets/ShopifyPlusIconLogo';
import {useSpainPricingPromotion} from './hooks/useSpainPricingPromotion';

// Plan Banner
export interface BannerProps {
  paidTrialAmount: string;
  paidTrialMonths: number;
  plansLength: number;
  colorTheme?: PlanCardTheme;
  isPlusPlan: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export function Banner({
  paidTrialAmount,
  paidTrialMonths,
  plansLength,
  colorTheme,
  isPlusPlan,
  isFirst,
  isLast,
}: BannerProps) {
  const {t} = useTranslations();

  return (
    <div
      className={cn(
        'flex items-center justify-center text-center',
        {
          'min-[1120px]:border-x max-[1120px]:border-l border-t border-solid px-4 py-2 text-sm':
            colorTheme === 'green' || colorTheme === 'blue',
        },
        {
          'min-[1120px]:border-starter-green max-[1120px]:border-t-starter-green bg-starter-green':
            colorTheme === 'green',
        },
        {
          'bg-lime-90 text-lime-10 p-4 leading-[1.125rem]':
            colorTheme === 'lime',
        },
        {
          'border-commerce-store-front bg-commerce-store-front text-white':
            colorTheme === 'blue',
        },
        {'min-[1120px]:rounded-t-2xl': plansLength > 1},
        {'rounded-tl-2xl': plansLength > 1 && isFirst},
        {'rounded-tr-2xl': plansLength > 1 && isLast},
        {'min-[900px]:rounded-t-2xl': plansLength === 1},
      )}
    >
      <span
        className={cn({
          'px-3 text-[1rem] ': colorTheme === 'lime',
          'w-full text-sm font-bold tracking-tight whitespace-nowrap':
            colorTheme === 'green' || colorTheme === 'blue',
        })}
      >
        {isPlusPlan
          ? t('pricing:paidTrialInlineBannerShopifyPlusOrion')
          : t('pricing:paidTrialInlineBanner', {
              paidTrialAmount: paidTrialAmount,
              count: paidTrialMonths,
            })}
      </span>
    </div>
  );
}

// Plan Details
export interface PlanDetailsProps {
  idx: number;
  colorTheme?: PlanCardTheme;
  sliderToggleId?: string;
  planName: {
    item: {
      internal_name: string;
      display_name: string;
      description: string;
      recommended: boolean;
    };
    badge?: string;
  };
  planPricing: {
    price: {
      currency_code: string;
      amount: number;
    };
    sliderValue: PricingSliderEnum;
    addCardContentDivider: boolean;
    discountAmount?: number;
  };
  disclaimer?: string;
  isPlusPlan: boolean;
}

export function PlanDetails({
  idx,
  colorTheme = 'green',
  sliderToggleId,
  planName,
  planPricing,
  disclaimer,
  isPlusPlan,
}: PlanDetailsProps) {
  const {t} = useTranslations();

  const {isSPRateIncentive} = useRetailIncentive();
  const {active, strikethroughPrice} = useSpainPricingPromotion({
    plan: planName.item,
    billingCycle: planPricing.sliderValue,
  });
  const spainPricingPromotionActive = active && Boolean(strikethroughPrice);

  const planNameMarkup = (
    <h3
      className={cn('font-bold', {
        'text-2xl tracking-tight':
          colorTheme === 'green' || colorTheme === 'blue',
        'text-3xl normal-case': colorTheme === 'lime',
      })}
    >
      {planName.item.display_name}
    </h3>
  );

  return (
    <div className="grid">
      <div
        className={cn(
          'flex w-full flex-col min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200  bg-white',
          {'px-4 pt-6': colorTheme === 'green' || colorTheme === 'blue'},
          {'px-5 lg:px-8 xl:px-10 pt-10 md:pt-8': colorTheme === 'lime'},
        )}
      >
        <div className="flex w-full justify-between">
          <div className="flex flex-col w-full">
            {isPlusPlan ? (
              <div className="flex flex-row justify-between">
                {planNameMarkup}
                <ShopifyPlusIconLogo />
              </div>
            ) : (
              planNameMarkup
            )}
          </div>
          {planName.badge && planName.item.recommended && (
            <div>
              {planName.item.recommended && idx === 0 ? (
                <span
                  className={cn({
                    'top-0 md:w-auto md:left-[50%] flex items-center justify-center text-sm leading-4 md:rounded-2xl whitespace-nowrap static bg-lime-90 text-lime-10 normal-case md:translate-x-0 md:translate-y-0 font-normal py-2 px-4 w-auto rounded-2xl h-fit':
                      colorTheme === 'lime',
                    'bg-starter-green rounded-full px-4 py-1 text-sm font-bold whitespace-nowrap':
                      colorTheme === 'green',
                    'bg-commerce-store-front rounded-full px-4 py-1 text-sm font-bold whitespace-nowrap':
                      colorTheme === 'blue',
                  })}
                >
                  {t('pricing:recommendedText')}
                </span>
              ) : null}
            </div>
          )}

          {planName.badge && !planName.item.recommended && (
            <div>
              <Typography
                as="span"
                className={cn(
                  'text-sm leading-4 whitespace-nowrap font-medium py-1 px-3 rounded-2xl',
                  {
                    'bg-[#DBF5E9] text-[#002133]': isSPRateIncentive,
                    'bg-shade-20 text-[#616161]': !isSPRateIncentive,
                  },
                )}
              >
                {planName.badge}
              </Typography>
            </div>
          )}
        </div>
        <p className="text-sm mt-1">{planName.item.description}</p>
      </div>

      <div
        className={cn(
          'flex flex-col justify-between min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200 px-4 pt-2 bg-white',
          {
            'px-5 lg:px-8 xl:px-10': colorTheme === 'lime',
          },
        )}
      >
        <div className="flex gap-1">
          <PriceDisplay
            sliderToggleId={sliderToggleId}
            planName={planName?.item?.internal_name}
            showPromoBanner={false}
            sliderValue={planPricing.sliderValue}
            currencyCode={
              spainPricingPromotionActive
                ? strikethroughPrice!.currencyCode
                : planPricing.price.currency_code
            }
            price={planPricing.price.amount}
            disclaimer={disclaimer}
            discountAmount={
              spainPricingPromotionActive
                ? strikethroughPrice!.amount
                : planPricing.discountAmount
            }
            subClassName={cn({
              'text-base leading-4 text-shade-80 font-bold flex flex-col':
                colorTheme === 'lime',
              'text-sm font-normal':
                colorTheme === 'green' || colorTheme === 'blue',
            })}
            isPlusPlan={isPlusPlan}
            showMonth
            promotion={
              spainPricingPromotionActive
                ? PromotionType.SpainPricingPromotion
                : undefined
            }
          />
        </div>
        <GmvRewardsIncentivePlanBadge
          supNumber={1}
          planName={planName.item.internal_name}
          containerClasses="mt-2"
        />
        {planPricing.addCardContentDivider && (
          <hr
            className={cn({
              'my-4': colorTheme === 'green' || colorTheme === 'blue',
              'mt-6': colorTheme === 'lime',
            })}
          />
        )}
      </div>
    </div>
  );
}

// Card Addon

export interface CardAddonProps {
  addOn: string;
  isStarter: boolean;
}

export function CardAddon({addOn, isStarter}: CardAddonProps) {
  return (
    <div className="p-4 bg-white min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200">
      {addOn && (
        <DivHtml
          className={cn(
            'px-4 w-full py-3.5 md:py-2 lg:py-3.5 lg:max-h-[50px] rounded ',
            {
              'bg-lime-10': !isStarter,
              'bg-pos-warm-gray': isStarter,
            },
          )}
        >
          {addOn}
        </DivHtml>
      )}
    </div>
  );
}
// Card Rates
export interface CardRatesProps {
  plan: PlanProps;
  colorTheme?: PlanCardTheme;
  isPlusPlan?: boolean;
  enableSpRateIncentive?: boolean;
}

export function CardRates({
  plan,
  colorTheme = 'green',
  isPlusPlan = false,
  enableSpRateIncentive,
}: CardRatesProps) {
  const {t} = useTranslations();

  const cardRates = Object.entries(plan.card_rates ?? {});
  const availableCardRates = cardRates.filter(
    ([key, value]) =>
      !!value &&
      (key === 'online' || key === 'in_person' || key === 'third_party'),
  );

  const spRateIncentiveValue =
    plan.incentives.retail_sp_rate_incentive?.card_rates?.in_person;

  const spRateIncentiveEligiblePlan = (key: string) => {
    const isEligible =
      key === 'in_person' &&
      ['retail', 'basic', 'professional', 'unlimited'].includes(
        plan.internal_name,
      ) &&
      enableSpRateIncentive;

    return isEligible;
  };

  return availableCardRates.length ? (
    <div
      className={cn(
        'flex flex-col min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200 px-4 bg-white text-sm',
        {
          'px-4': colorTheme === 'green' || colorTheme === 'blue',
          'px-5 lg:px-8 xl:px-10 pt-5': colorTheme === 'lime',
        },
      )}
    >
      <p className="mb-4 font-semibold whitespace-nowrap">
        {isPlusPlan ? t('pricing:rates') : t('pricing:ratesStartingAt')}
      </p>
      {isPlusPlan ? (
        <p className="mb-2">{t('pricing:plusCompetitiveCardRates')}</p>
      ) : (
        <ul
          className={cn('grid ', {
            'gap-2': colorTheme === 'green',
            'gap-4 pt-2 pb-2': colorTheme === 'lime',
          })}
        >
          {availableCardRates.map(([key, value]) => (
            <li
              key={key}
              className={cn('gap-2.5 inline-flex', {
                'items-center': enableSpRateIncentive,
                'items-start': !enableSpRateIncentive,
              })}
            >
              <SvgCheck
                height="20"
                width="20"
                className={cn('flex-shrink-0', {
                  'text-[#799102]': colorTheme === 'lime',
                })}
              />
              {spRateIncentiveEligiblePlan(key) ? (
                <>
                  <del>{value}</del>
                  <span className="bg-[#DBF5E9] text-[#303030] rounded-[10px] px-3 py-2">
                    {spRateIncentiveValue}*
                  </span>
                </>
              ) : (
                value
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : (
    <div className="bg-white min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200" />
  );
}

// Standout Features
export interface StandoutFeaturesProps {
  plan: PlanProps;
  colorTheme?: PlanCardTheme;
}

export function StandoutFeatures({
  plan,
  colorTheme = 'green',
}: StandoutFeaturesProps) {
  const {t} = useTranslations();

  return plan.features.length ? (
    <div
      className={cn(
        'flex flex-col min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200  bg-white text-sm',
        {
          'px-4 pt-3.5': colorTheme === 'green' || colorTheme === 'blue',
          'px-5 lg:px-8 xl:px-10 pt-3.5': colorTheme === 'lime',
        },
        plan.features.length === 1 ? 'pb-3.5' : 'pt-3.5',
      )}
    >
      {plan.features.length > 1 ? (
        <p className="mb-4 font-semibold">{t('pricing:standoutFeatures')}</p>
      ) : null}
      <ul
        className={cn('grid ', {
          'gap-2': colorTheme === 'green' || colorTheme === 'blue',
          'gap-4': colorTheme === 'lime',
        })}
      >
        {plan.features.map((feature) => {
          return (
            <li className="gap-2.5 inline-flex items-start" key={feature.name}>
              <SvgCheck
                height="20"
                width="20"
                className={cn('flex-shrink-0', {
                  'text-[#799102]': colorTheme === 'lime',
                })}
              />
              {feature.summary}
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <div className="bg-white min-[1120px]:border-x max-[1120px]:border-l border-solid border-gray-200" />
  );
}

// Plan Cta
export interface PlanCardCTAProps {
  plansLength: number;
  item: {
    display_name: string;
  };
  price: {
    amount: number;
    currency_code: string;
  };
  ctaLinkUrl: string;
  ctaLinkText: string;
  colorTheme?: PlanCardTheme;
  secondaryLink?: {
    url?: string;
    text?: string;
  };
  disclaimer?: string;
  isPlusPlan?: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export function PlanCardCTA({
  plansLength,
  item,
  price,
  ctaLinkUrl,
  ctaLinkText,
  colorTheme = 'green',
  disclaimer,
  isFirst,
  isLast,
}: PlanCardCTAProps) {
  const {getSignupURL} = useSignupUrl();

  return (
    <div
      className={cn(
        'min-[1120px]:border-x max-[1120px]:border-l border-b border-solid border-gray-200 pb-8 bg-white text-center pt-6',
        {'px-4': colorTheme === 'green' || colorTheme === 'blue'},
        {'px-5 lg:px-8 xl:px-10': colorTheme === 'lime'},
        {'min-[1120px]:rounded-b-2xl': plansLength > 1},
        {'rounded-bl-2xl': plansLength > 1 && isFirst},
        {'rounded-br-2xl': plansLength > 1 && isLast},
        {'min-[900px]:rounded-b-2xl': plansLength === 1},
      )}
      data-component-extra-plan-name={item.display_name}
      data-component-extra-plan-amount={price.amount}
      data-component-extra-plan-currency={price.currency_code}
    >
      <Button
        className="w-full"
        intent="primary"
        componentName={START_FREE_TRIAL_NAME}
        href={getSignupURL({url: ctaLinkUrl}).href}
      >
        {ctaLinkText}
      </Button>
      {disclaimer && (
        <Typography
          as="span"
          className="text-xs text-left block text-shade-50 mt-4"
        >
          *
          <span
            dangerouslySetInnerHTML={{
              __html: disclaimer,
            }}
          />
        </Typography>
      )}
    </div>
  );
}

export function PlanCardCTAWithSecondaryLink({
  plansLength,
  item,
  price,
  ctaLinkUrl,
  ctaLinkText,
  colorTheme = 'green',
  secondaryLink,
  isPlusPlan,
  isFirst,
  isLast,
}: PlanCardCTAProps) {
  const {localizePath} = useTranslations();
  const {getSignupURL} = useSignupUrl();

  return (
    <div
      className={cn(
        'min-[1120px]:border-x max-[1120px]:border-l border-b border-solid border-gray-200 pb-8 bg-white text-center flex flex-col gap-2 pt-6',
        {'px-4': colorTheme === 'green' || colorTheme === 'blue'},
        {'px-5 lg:px-8 xl:px-10': colorTheme === 'lime'},
        {'min-[1120px]:rounded-b-2xl': plansLength > 1},
        {'rounded-bl-2xl': plansLength > 1 && isFirst},
        {'rounded-br-2xl': plansLength > 1 && isLast},
      )}
      data-component-extra-plan-name={item.display_name}
      data-component-extra-plan-amount={price.amount}
      data-component-extra-plan-currency={price.currency_code}
    >
      <Button
        className="w-full"
        intent="primary"
        componentName={isPlusPlan ? PLUS_GET_IN_TOUCH : START_FREE_TRIAL_NAME}
        href={
          isPlusPlan
            ? localizePath(ctaLinkUrl)
            : getSignupURL({url: ctaLinkUrl}).href
        }
      >
        {ctaLinkText}
      </Button>
      {secondaryLink?.url && (
        <span className="mt-4">
          <a
            className="underline hover:no-underline font-bold"
            href={secondaryLink.url}
          >
            {secondaryLink.text}
          </a>
        </span>
      )}
    </div>
  );
}
