import type {PlanComponent} from '@/types';
import type {PricingSliderEnum} from '@/enums';

import type {
  Plan,
  PaidTrialType,
} from './FeaturesTable/utils/normalizePlanTypes';
export type PlanCardTheme = 'green' | 'lime' | 'blue';

export type ComponentKey =
  | 'Banner'
  | 'PlanDetails'
  | 'CardAddon'
  | 'CardRates'
  | 'StandoutFeatures'
  | 'PlanCardCTA'
  | 'PlanCardCTAWithSecondaryLink';

export type Maybe<T = unknown> = null | undefined | T;

export interface ResponsivePlanCardsRedesignProps {
  serverPlansData?: {
    mainPlans: Plan[];
    alternatePlans: Plan[];
  };
  displayAlternatePlans?: boolean;
  rowsToDisplay?: ComponentKey[];
  alternatePlansTranslations?: PlanComponent[];
  badge?: string;
  colorTheme?: PlanCardTheme;
  addCardContentDivider?: boolean;
  mobileTabActivePlan: string;
  sliderValue: PricingSliderEnum;
  sliderToggleId?: string;
  showSpRateIncentive?: boolean;
  toggleId: string;
  paidTrial: PaidTrialType;
}

export interface PlanCardsProps
  extends Pick<
    NonNullable<ResponsivePlanCardsRedesignProps['serverPlansData']>,
    'mainPlans'
  > {
  retailPosPrice?: {
    monthly?: number;
    yearly?: number;
    currencyCode?: string;
  };
}
export interface PlanCardsProps
  extends Pick<
    ResponsivePlanCardsRedesignProps,
    | 'sliderValue'
    | 'colorTheme'
    | 'badge'
    | 'rowsToDisplay'
    | 'displayAlternatePlans'
    | 'alternatePlansTranslations'
    | 'addCardContentDivider'
    | 'sliderToggleId'
    | 'showSpRateIncentive'
    | 'paidTrial'
  > {}

export type PlanProps = PlanCardsProps['mainPlans'][number];

export enum PromotionType {
  SpainPricingPromotion = 'spain_pricing_promotion',
}
