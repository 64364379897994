import type {SVGAttributes} from 'react';

function SvgComponent(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#clip0_15397_32534)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5186 7.90566H26.2878L26.5249 6.68765C26.5858 6.37484 26.3704 6.11047 26.0544 6.11047C25.7382 6.11047 25.4194 6.37484 25.3585 6.68765L25.1216 7.90566H23.8908C23.5748 7.90566 23.2567 8.17002 23.1958 8.48283C23.135 8.79564 23.3504 9.06 23.6664 9.06H24.8971L24.6601 10.278C24.5992 10.5908 24.8146 10.8552 25.1307 10.8552C25.4467 10.8552 25.7656 10.5908 25.8257 10.278L26.0627 9.06H27.2934C27.6088 9.06 27.9276 8.79564 27.9885 8.48283C28.0506 8.17072 27.8346 7.90566 27.5186 7.90566Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2915 4.60801C13.2817 3.95402 13.2027 3.04467 12.8951 2.25849C13.8852 2.44396 14.3726 3.55263 14.5781 4.21354C14.1971 4.3305 13.7628 4.46338 13.2915 4.60801ZM8.10927 13.2822C8.19738 14.6642 11.8712 14.9659 12.0775 18.2034C12.2397 20.7501 10.7126 22.492 8.51204 22.6297C5.87099 22.7944 4.41726 21.2526 4.41726 21.2526L4.97666 18.8961C4.97666 18.8961 6.44018 19.9889 7.61141 19.9154C8.37639 19.8678 8.64979 19.2518 8.62252 18.8158C8.50784 17.013 5.51647 17.1189 5.32698 14.1569C5.16825 11.6641 6.82196 9.1381 10.472 8.90979C11.8782 8.8219 12.5984 9.17692 12.5984 9.17692L11.7635 12.2669C11.7635 12.2669 10.8328 11.8475 9.72873 11.9161C8.10997 12.0192 8.09249 13.0295 8.10927 13.2822ZM10.6481 1.46608C10.9543 1.45985 11.2123 1.52629 11.4326 1.66954C11.0802 1.85086 10.739 2.11107 10.4194 2.45018C9.5908 3.32978 8.95588 4.69589 8.70276 6.01356C7.91261 6.25578 7.13994 6.49239 6.42951 6.71046C6.87773 4.63638 8.63423 1.52352 10.6481 1.46608ZM12.4244 4.87379C11.5559 5.14016 10.6077 5.43089 9.65607 5.72224C9.92388 4.70838 10.4308 3.69868 11.0539 3.03708C11.2853 2.79071 11.6098 2.51665 11.9937 2.35956C12.3552 3.10421 12.4328 4.16028 12.4244 4.87379ZM16.2148 3.71186C16.2127 3.71255 15.9141 3.80391 15.4093 3.95892C15.3254 3.68826 15.2009 3.35539 15.024 3.02112C14.4534 1.94367 13.6171 1.37272 12.6074 1.37127H12.6039C12.534 1.37127 12.4641 1.3775 12.3941 1.3838C12.3648 1.34843 12.3347 1.31321 12.3025 1.27999C11.8627 0.814236 11.2977 0.587173 10.6222 0.60662C9.31745 0.643991 8.01825 1.57612 6.96518 3.23227C6.22328 4.39762 5.66039 5.86207 5.50026 6.99496C4.00248 7.45379 2.95431 7.7756 2.93194 7.78252C2.17605 8.01712 2.15228 8.04065 2.05298 8.74586C1.97817 9.27943 0 24.4209 0 24.4209L16.4141 27.2306V3.67857C16.333 3.68411 16.2603 3.69864 16.2148 3.71186Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.957 27.1669L23.7663 25.4915C23.7663 25.4915 20.8343 5.87317 20.8162 5.73822C20.7973 5.60396 20.6784 5.52922 20.5805 5.52092C20.4826 5.51261 18.5653 5.48424 18.5653 5.48424C18.5653 5.48424 17.3962 4.36104 16.957 3.93542V27.1669Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_15397_32534">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
